import React from "react"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../../components/layout"
import style from "./index.module.css"
import AndvicHeader from "../../../components/andvic-header"
import BurgerMenu from "../../../components/burger-menu"

import Carousel, { Modal, ModalGateway, ViewType } from "react-images"
import SEO from "../../../components/seo"

export interface IAppProps {
  data: any
}

export interface IAppState {
  modalIsOpen: boolean
  selectedIndex?: number
}

class Gallery extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props)

    this.state = {
      modalIsOpen: false,
      selectedIndex: 0,
    }
  }

  toggleLightbox = (selectedIndex: number = 0) => {
    this.setState(state => ({
      modalIsOpen: !state.modalIsOpen,
      selectedIndex,
    }))
  }

  public render() {
    const { data } = this.props

    const images = data.galleryImages.edges.map((x: any) => ({
      source: x.node.childImageSharp.fluid.src,
      originalName: x.node.childImageSharp.fluid.originalName,
    }))

    const sortedImage = [...images].sort((a: any, b: any) =>
      a.originalName > b.originalName
        ? 1
        : b.originalName > a.originalName
        ? -1
        : 0
    )

    console.log("yolllloo", sortedImage)

    const { modalIsOpen, selectedIndex } = this.state

    return (
      <>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal
              closeOnBackdropClick={true}
              closeOnEsc={true}
              onClose={_e => {
                this.toggleLightbox(0)
              }}
            >
              <Carousel
                currentIndex={selectedIndex}
                styles={{
                  footerCaption: (base, state) => ({
                    ...base,
                    display: "none",
                  }),
                  view: base => ({
                    ...base,
                    "& > img": {
                      margin: "auto",
                    },
                  }),
                }}
                views={sortedImage}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <div onClick={_e => this.toggleLightbox(0)}>
          <Img fluid={data.lounge.childImageSharp.fluid} />
        </div>
        <div onClick={_e => this.toggleLightbox(2)}>
          <Img fluid={data.outdoor.childImageSharp.fluid} />
        </div>
        <div onClick={_e => this.toggleLightbox(2)}>
          <Img fluid={data.lounge2.childImageSharp.fluid} />
        </div>
        <div onClick={_e => this.toggleLightbox(3)}>
          <Img fluid={data.bathroom.childImageSharp.fluid} />
        </div>
      </>
    )
  }
}

const BackgroundSection = ({
  className,
  data,
  header,
}: {
  className: any
  data: any
  header: any
}) => {
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <>
      <BackgroundImage Tag="section" className={className} fluid={imageData}>
        <AndvicHeader></AndvicHeader>

        <h1 className={style.pageHeader}>
          <span>{header}</span>
        </h1>
      </BackgroundImage>
    </>
  )
}

export default function IndexPage(): JSX.Element {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(
        relativePath: {
          eq: "mosman/TFPhoto_Anita Kane_Killarney St_0009_RT.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }

      lounge: file(name: { eq: "1-drone" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      outdoor: file(name: { eq: "2-drone" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      lounge2: file(name: { eq: "3-outdoor" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      bathroom: file(name: { eq: "4-garage" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      bathrooms: file(name: { eq: "5-outdoor" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      bathroomss: file(name: { eq: "6-kitchen" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      galleryImages: allFile(
        filter: { relativeDirectory: { eq: "mosman/gallery" } }
      ) {
        edges {
          node {
            relativePath
            relativeDirectory
            dir
            childImageSharp {
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }

      portfolioOne: file(
        relativePath: {
          eq: "mosman/TFPhoto_Anita Kane_Killarney St_0146_RT.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Mosman - Portfolio" />
      <BurgerMenu></BurgerMenu>

      <BackgroundSection
        data={data}
        className={style.header}
        header="Mosman"
      ></BackgroundSection>

      <div className={style.portfolioMain}>
        <div>
          <h2 className={style.portfolioSubheader}>Family Entertainer</h2>
          <p>
            Modern open plan living transformation with warm timber finishes.
          </p>
          <p>Perfect for entertaining families and guests.</p>
        </div>
        <div className={style.home2}>
          <div>
            <Img fluid={data.portfolioOne.childImageSharp.fluid} />
          </div>
        </div>
      </div>
      <div className={style.portfolioGallery}>
        <Gallery data={data} />
      </div>
    </Layout>
  )
}
